import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Content, {HTMLContent} from '../components/Content'
import {Col, Row, Card, CardBody} from "reactstrap";
// import UpdatePageTags from "../components/UpdatePageTags";

export const UpdateEnPageTemplate = ({
                                      content,
                                      contentComponent,
                                      description,
                                      tags,
                                      title,
                                      helmet,
                                      published_date,
                                      modified_date,
                                 }) => {
  const PostContent = contentComponent || Content

  return (
    <div>
      <Row>
        <Col md={{size: 8, offset: 2}}>
          <Card>
            <CardBody>
              <section className="section">
                {helmet || ''}
                <div className="container content container-info update-page">
                  {/*<CardTitle>*/}
                  {/*<h1 className="display-7 articleTitle">{title}</h1>*/}
                  {/*</CardTitle>*/}
                  {/*<p>{description}</p>*/}
                  {/*<hr />*/}
                  <PostContent content={content} className="infoSection"/>
                  <p className="text-right text-muted">
                    <span> Published: {published_date} </span><br />
                    <span> Modified: {modified_date} </span>
                  </p>
                </div>
              </section>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

UpdateEnPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  published_date: PropTypes.string,
  modified_date: PropTypes.string,
}

class UpdateEnPage extends React.Component {

  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener('load', this.updateDimensions);
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions()
  }

  updateDimensions() {
    var ua = navigator.userAgent.toLowerCase();
    var isApp = ua.indexOf('anbai') > -1
    let element = document.getElementById('main-wrapper');
    if (isApp) {
      element.classList.add('is-app');
    } else {
      element.classList.remove('is-app');
    }
    // let element = document.getElementById('main-wrapper');
    // const width = window.innerWidth
    // if (width < 1170) {
    //   element.setAttribute('data-sidebartype', 'mini-sidebar');
    //   element.classList.add('mini-sidebar');
    // } else {
    //   element.setAttribute(
    //     'data-sidebartype',
    //     'full'
    //   );
    //   element.classList.remove('mini-sidebar');
    // }
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.updateDimensions);
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {

    const data = this.props.data
    const {markdownRemark: post} = data

    return (
      <Layout breadcrumbs={[{name: 'App updates', link: '/update-en/'}]}>
        <UpdateEnPageTemplate
          content={post.html}
          contentComponent={HTMLContent}
          description={post.frontmatter.description}
          helmet={
            <Helmet>
              <title>{`${post.frontmatter.title} | ${data.site.siteMetadata.title}`}</title>
              <meta
                name="description"
                content={`${data.site.siteMetadata.description}${post.frontmatter.description}`}
              />
            </Helmet>
          }
          tags={post.frontmatter.tags}
          title={post.frontmatter.title}
          published_date={post.frontmatter.published_date}
          modified_date={post.frontmatter.modified_date}
        />
      </Layout>
    )
  }
}

UpdateEnPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default UpdateEnPage

export const pageQuery = graphql`
  query UpdateEnPageByID($id: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        published_date(formatString: "YYYY/MM/DD")
        modified_date(formatString: "YYYY/MM/DD")
        title
        description
        tags
      }
    }
  }
`
